export const environment = {
  production: true,
  hmr: false,
  apiUrl: 'https://callcenter-api-stable.xdevs.uz',
  wsUrl: 'wss://callcenter-socket-stable.xdevs.uz',
  wsNtfUrl: 'wss://notify-stable.xdevs.uz',
  chatUrl: 'https://callcenter-chat-stable.xdevs.uz',
  chatWsUrl: 'callcenter-chat-stable.xdevs.uz',
  corpChatUrl: 'https://callcenter-erp-chat-stable.xdevs.uz',
  minioUrl: 'https://crm-minio-stable.xdevs.uz',
  wsHelpDeskUrl: 'wss://crm-help-desk-socket-stable.xdevs.uz',
  whipUrl:'http://192.168.25.57:8889'
};
